import VisualizerDummy from "../Components/VisualizerDummy";
import useMultiStepForm from "../Services/useMultiStepFrom";
import CustomizeSongStyle from "../Components/CustomizeSongStyle";
import { useState } from "react";
import { useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import incrementUserCredits from "../Services/incrementCredits";
import { validateUserEmailForSong } from "../Services/validateUserEmailForSong";
import { songCreditCost } from "../Services/magicNumbers";
import { makeSongRequest } from "../Services/MakeSongRequest";
import { useNavigate } from "react-router-dom";
import sendConfirmation from "../Services/sendConfirmation";

/*
const steps = [
  {
    label: "Paste Lyrics",
    component: LyicsInput,
  },
  {
    label: "Select Genre",
    component: SongStyleInput,
  },
  {
    label: "Email",
    component: EmailInput,
  },
];*/
const steps = [];

export default function CTA() {
  const { currentStepIndex, next } = useMultiStepForm(steps);
  const [email, setEmail] = useState("");
  const [lyrics, setLyrics] = useState("");
  const [genres] = useState([]);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const navigate = useNavigate();

  const defaultText = "copy/paste your study materials here... ❤️";

  const handleFocus = (event) => {
    console.log("handling focus");
    if (event.target.value === defaultText) {
      event.target.value = "";
    }
  };

  const handleCustomSongData = (isValid) => {
    setIsSubmitEnabled(isValid); // Enable the submit button based on the child's state
    console.log(isSubmitEnabled);
  };

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setEmail(user.email);
      }
    });

    return () => unsubscribe();
  }, []);

  async function sendSubmission(event) {
    event.preventDefault(); // Prevent page refresh on form submission
    console.log("sending submission");
    const valid = await validateUserEmailForSong(email); //validate that user has credits

    if (valid) {
      localStorage.setItem("email", email); // Save email to local storage for later
      const res = makeSongRequest({
        email: email,
        lyrics: lyrics,
        genres: genres,
      });
      res.then((val) => {
        incrementUserCredits(email, -songCreditCost);
        sendConfirmation({ email: email });
        navigate("success");
      });
    } else {
      navigate("payements");
      console.log("no credits");
    }
  }

  return (
    <>
      <div className="bg-regal-blue w-full min-h-screen">
        <div className="px-6 py-2 pb-6 sm:px-6 sm:py-2 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-balance text-4xl font-semibold tracking-tight text-regal-beige sm:text-5xl mb-5">
              text to tracks
            </h2>
            <h3 className="text-balance text-xl font-semibold tracking-tight text-regal-beige sm:text-xl mb-5">
              Transform your boring study materials into memorable study songs
            </h3>
            <>
              <VisualizerDummy className="w-full h-48"></VisualizerDummy>
            </>
            <form onSubmit={sendSubmission}>
              {currentStepIndex === 0 && (
                <>
                  <textarea
                    id="lyrics"
                    name="lyrics"
                    rows={7}
                    onFocus={handleFocus}
                    onChange={(e) => setLyrics(e.target.value)}
                    className="block w-full bg-regal-beige rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-focus-ring-color sm:text-sm sm:leading-6 mt-5"
                    defaultValue={defaultText}
                  />
                  <CustomizeSongStyle
                    onValidationChange={handleCustomSongData}
                  ></CustomizeSongStyle>
                  <button
                    className={`rounded-md w-full bg-regal-blue outline-regal-beige outline outline-2 px-3 py-2 text-sm font-semibold text-regal-beige shadow-sm hover:bg-regal-beige hover:text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-focus-ring-color mt-5 ${
                      !isSubmitEnabled ? "line-through opacity-50" : ""
                    }`}
                    onClick={next}
                    disabled={!isSubmitEnabled}
                  >
                    create your free song 🎵
                  </button>
                </>
              )}
              {currentStepIndex === 1 && (
                <>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Email address
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="block w-full bg-regal-beige rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-focus-ring-color sm:text-sm sm:leading-6"
                    />
                  </div>
                  <button
                    className=" rounded-md w-full bg-regal-blue outline-regal-beige outline outline-2 px-3 py-2 text-sm font-semibold text-regal-beige shadow-sm hover:bg-regal-beige hover:text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mt-5"
                    type="submit"
                  >
                    send me my song 🎵
                  </button>
                </>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

import axios from "axios";

async function sendConfirmation(userInfo) {
  const url = "https://sendcustomeremail-y4zrrpqboq-uc.a.run.app";
  const email = userInfo.email;
  return axios.get(url, {
    params: { email: email },
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export default sendConfirmation;
